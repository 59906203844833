import { showOpenFilePicker } from "native-file-system-adapter"
import { Annotation } from "./Annotation"
import LocalStorage from "./LocalStorage"
import { Transcript } from "./Transcript"

export class MediaFile {
    id
    path
    filename

    fileHandle
    mediaBlobURL
    peaksBlobURL
    transcript
    annotations = []

    // TODO: review which fields are used
    constructor({ id, path, filename, fileHandle }) {
        this.id = id
        this.path = path
        this.filename = filename
        this.fileHandle = fileHandle
    }

    basename() {
        return this.filename.replace(/.*\//, "")
    }

    shortName() {
        return this.basename().split(".")[0]
    }

    ext() {
        return this.basename().split(".")[1] || ""
    }

    getBlobUrlSrc() {
        // Bit of a hack, but it seems videojs accepts "video/mp4" for whatever chrome can play
        if (this.peaksBlobURL === undefined)
            return {
                type: "video/mp4",
                src: this.mediaBlobURL,
            }
        else
            return {
                type: "video/mp4",
                src: this.mediaBlobURL,
                peaks: this.peaksBlobURL,
            }
    }

    async loadFiles(localStorage) {
        const mediaFile = await this.fileHandle.getFile()
        this.mediaBlobURL = URL.createObjectURL(mediaFile)

        const peaksFileHandle = await localStorage.getPeaksFileHandle(this.id)
        if (peaksFileHandle) {
            this.peaksBlobURL = URL.createObjectURL(await peaksFileHandle.getFile())
        }

        const transcriptFileHandle = await localStorage.getTranscriptFileHandle(this.id)
        if (transcriptFileHandle) {
            const transcriptFileData = await LocalStorage.readFileAsync(
                await transcriptFileHandle.getFile(),
            )
            this.transcript = await Transcript.fromVTT(transcriptFileData)
        }

        const annotationsFileHandle = await localStorage.getAnnotationsFileHandle(this.id)
        this.annotations = await Annotation.readFromFile(annotationsFileHandle)
    }

    async tryAddVttFile(localStorage, onSuccess) {
        try {
            const sourceVttFiles = await showOpenFilePicker({ multiple: false })
            const sourceVttFile = sourceVttFiles[0]
            const destVttHandle = await localStorage.getTranscriptFileHandle(this.id, true)
            await localStorage.copyFile(sourceVttFile, destVttHandle)
            onSuccess()
        } catch (e) {
            if (e.name === "AbortError") {
                console.log("User aborted file selection in MediaFile.tryAddVttFile()")
            }
        }
    }
    
}
