import Divider from "@mui/material/Divider"
import MuiDrawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import { styled, useTheme } from "@mui/material/styles"
import {
    CaretLeft,
    Folder,
    FolderOpen,
    List as ListIcon,
    Notebook,
    TagSimple,
    X,
} from "@phosphor-icons/react"
import * as React from "react"
import { Link as RouterLink } from "react-router"
import FileExplorerComponent from "./FileExplorerComponent"

const drawerWidth = 280

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
})

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    }),
)

export default function MiniDrawer({ localStorage, onLoadFolderClicked, onFolderClosed }) {
    const theme = useTheme()
    const [open, setOpen] = React.useState(true)

    const [anchorEl, setAnchorEl] = React.useState(null)
    const currentDirectroyPopoverOpen = Boolean(anchorEl)
    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const handleDrawerOpenClose = () => {
        setOpen(!open)
    }

    return (
        <Drawer
            variant="permanent"
            open={open}
            sx={{ display: "flex", height: "100%" }}
            PaperProps={{ sx: { backgroundColor: "#eaf0f6" } }}
        >
            <DrawerHeader sx={{ backgroundColor: theme.palette.primary.main }}>
                <Typography
                    variant="h5"
                    sx={{
                        mr: "auto",
                        ml: 1,
                        color: theme.palette.grey[200],
                        fontWeight: 700,
                        visibility: open ? "visible" : "hidden",
                    }}
                >
                    DING!
                </Typography>
                <IconButton onClick={handleDrawerOpenClose}>
                    {!open && <ListIcon color={theme.palette.grey[200]} />}
                    {open &&
                        (theme.direction === "rtl" ? (
                            <CaretLeft color={theme.palette.grey[200]} />
                        ) : (
                            <CaretLeft
                                weight="bold"
                                size={16}
                                style={{ color: theme.palette.grey[200] }}
                            />
                        ))}
                </IconButton>
            </DrawerHeader>

            <List disablePadding>
                <ListItemButton onClick={handlePopoverClick}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <FolderOpen size={24} />
                    </ListItemIcon>
                    <ListItemText
                        sx={{ color: grey[700] }}
                        primary={<b>{localStorage.storageBaseDir.name}</b>}
                        secondary="Current Directory"
                    />
                </ListItemButton>

                <Menu
                    open={currentDirectroyPopoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                >
                    <MenuList dense sx={{ p: 0 }}>
                        <MenuItem
                            onClick={() => {
                                handlePopoverClose()
                                onLoadFolderClicked()
                            }}
                        >
                            <ListItemIcon>
                                <Folder size={16} weight="bold" />
                            </ListItemIcon>
                            <ListItemText>Open other folder...</ListItemText>
                        </MenuItem>

                        <MenuItem onClick={onFolderClosed}>
                            <ListItemIcon>
                                <X size={16} weight="bold" />
                            </ListItemIcon>
                            <ListItemText>Close</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>

                <Divider />

                <Link component={RouterLink} to="/app" underline="none">
                    <ListItemButton>
                        <ListItemIcon>
                            <Notebook size={24} color={theme.palette.primary.main} />
                        </ListItemIcon>
                        <ListItemText sx={{ color: "primary.main" }}>Overview</ListItemText>
                    </ListItemButton>
                </Link>
                <Link component={RouterLink} to="/app/tagindex" underline="none">
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <TagSimple size={24} color={theme.palette.primary.main} />
                            </ListItemIcon>
                            <ListItemText>Tags</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
            </List>

            <Divider sx={{ mb: 1 }} />

            <FileExplorerComponent
                width={drawerWidth}
                localStorage={localStorage}
                sx={{
                    visibility: open ? "visible" : "hidden",
                    flexGrow: 1,
                    height: "0px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    color: grey[700],
                }}
            />
        </Drawer>
    )
}
