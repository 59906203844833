import * as React from "react"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import ContentEditable from "../../Common/ContentEditablePlaintext.tsx"

export default function TranscriptLine({
    t,
    transcriptArray,
    index,
    mediaCursorTimestamp,
    onTranscriptWordClick,
    isEditing,
    persist,
    className
}) {
    const contentText = React.useRef(t.text)
    const [, forceUpdate] = React.useReducer((x) => x + 1, 0)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    React.useEffect(() => {
        contentText.current = t.text
        forceUpdate()
    }, [isEditing, t])

    const handleChange = (e) => {
        contentText.current = e.target.value
    }

    const handleBlur = () => {
        persist(index, contentText.current)
    }

    const timestamp = (timestamp) => {
        return (
            <Typography
                variant="caption"
                style={{
                    marginRight: "8px",
                    opacity: "0.5",
                    fontWeight: "bold",
                    borderRadius: 4,
                    userSelect: "none",
                    padding: "4px",
                    paddingTop: "2px",
                }}
            >
                {timestamp}
            </Typography>
        )
    }

    return (
        <Box
            sx={{
                display: "flex",
                padding: "4px",
                cursor: isEditing ? "select" : "pointer",
            }}
            className={`ts-line ${className}`}
            data-timecode={t.start}
            onClick={onTranscriptWordClick}
        >
            {timestamp(t.startFormatted)}
            <ContentEditable
                disabled={!isEditing}
                html={contentText.current} // innerHTML of the editable div
                onChange={handleChange} // handle innerHTML change
                tagName="span" // Use a custom HTML tag (uses a div by default)
                style={{ outline: "none", width: "100%" }}
                onBlur={handleBlur}
            />
        </Box>
    )
}