import * as IdbKeyval from "idb-keyval"
import { showDirectoryPicker } from "native-file-system-adapter"
import { JobManager } from "./JobManager"
import { MediaFile } from "./MediaFile"
import { Transcript } from "./Transcript"
import { Annotation } from "./Annotation"

const DING_SUBDIR = "ding"
export const NOTES_SUBDIR = "notes"
export const MEDIA_SUBDIR = "media"
export const PEAKS_SUBDIR = "peaks"
export const TRANSCRIPT_SUBDIR = "transcripts"
export const JOBS_FILE = "jobs.json"

const IDB_KEY_STORAGE_BASE_DIR_HANDLE = "storageBaseDir"

export default class LocalStorage {
    storageBaseDir
    jobManager = new JobManager()

    // State stored
    // Filename => mediaFile
    mediaFiles = {}

    // React External Store
    listeners = []

    subscribe = (listener) => {
        this.listeners = [...this.listeners, listener]
        return () => {
            this.listeners = this.listeners.filter((l) => l !== listener)
        }
    }

    getSnapshot = () => {
        return this.mediaFiles
    }

    notifyListeners() {
        for (const listener of this.listeners) {
            listener()
        }
    }

    // Local Storage
    async tryOpenPersistedBaseDir() {
        const storageBaseDir = await IdbKeyval.get(IDB_KEY_STORAGE_BASE_DIR_HANDLE)
        if (!storageBaseDir) return
        try {
            const gotPermission = await this.verifyPermission(storageBaseDir)
            const gotPersist = await this.verifyPersist()
            if (!gotPermission || !gotPersist) {
                return false
            }
            await storageBaseDir.getDirectoryHandle(NOTES_SUBDIR, { create: true })
            this.storageBaseDir = storageBaseDir
            await this.onDirectoryOpened()
        } catch (e) {
            if (e.name === "NotFoundError") {
                // Directory no longer exists (eg. now deleted or unmounted)
                return false
            } else {
                console.log(e)
            }
        }
        return this.storageBaseDir ? true : false
    }

    // Return true if directory was selected
    async trySelectStorageBaseDir(onSuccess) {
        try {
            this.storageBaseDir = await showDirectoryPicker()
            IdbKeyval.set(IDB_KEY_STORAGE_BASE_DIR_HANDLE, this.storageBaseDir)
            await this.onDirectoryOpened()
            return true
        } catch (e) {
            if (e.name === "AbortError") {
                console.log("User aborted folder selection in trySelectStorageBaseDir()")
            }
        }
        return false
    }

    async verifyPermission(fileHandle, readWrite) {
        // https://developer.chrome.com/docs/capabilities/web-apis/file-system-access#stored_file_or_directory_handles_and_permissions
        const options = {}
        if (readWrite) {
            options.mode = "readwrite"
        }
        // Check if permission was already granted. If so, return true.
        if ((await fileHandle.queryPermission(options)) === "granted") {
            return true
        }
        // Request permission. If the user grants permission, return true.
        if ((await fileHandle.requestPermission(options)) === "granted") {
            return true
        }
        // The user didn't grant permission, so return false.
        return false
    }

    async verifyPersist() {
        // Request persistent storage for site
        if (navigator.storage && navigator.storage.persist) {
            const isPersisted = await navigator.storage.persist()
            return isPersisted
        }
        return false
    }

    async onDirectoryOpened() {
        this.loadJobManager()
        await this.scanForMediaFiles()
    }

    async reset() {
        this.storageBaseDir = null
        await IdbKeyval.del(IDB_KEY_STORAGE_BASE_DIR_HANDLE)
    }

    async copyFile(sourceFileHandle, destFileHandle) {
        const sourceFile = await sourceFileHandle.getFile()
        const destWritable = await destFileHandle.createWritable()
        await sourceFile.stream().pipeTo(destWritable)
    }

    /**
     * @returns String of the directory user has selected as base dir.
     */
    getCurrentWorkingDirectory() {
        return this.storageBaseDir.name
    }

    async loadJobManager() {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const jobManagerFileHandle = await dingDir.getFileHandle(JOBS_FILE, { create: true })
        const jobManagerFile = await jobManagerFileHandle.getFile()
        const jobManagerJsonString = await LocalStorage.readFileAsync(jobManagerFile)
        this.jobManager.load(this, jobManagerJsonString)
    }

    async saveJobs(serializedJobs) {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const jobManagerFileHandle = await dingDir.getFileHandle(JOBS_FILE, { create: true })
        LocalStorage.writeFile(jobManagerFileHandle, serializedJobs)
    }

    async getPeaksFileHandle(mediaID) {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const peaksDir = await dingDir.getDirectoryHandle(PEAKS_SUBDIR, { create: true })
        try {
            return await peaksDir.getFileHandle(mediaID + ".json", { create: false })
        } catch (e) {
            if (e.name === "NotFoundError") return null
        }
        return null
    }

    async getTranscriptFileHandle(mediaID, shouldCreate) {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const transcriptDir = await dingDir.getDirectoryHandle(TRANSCRIPT_SUBDIR, { create: true })
        try {
            return await transcriptDir.getFileHandle(mediaID + ".vtt", {
                create: shouldCreate || false,
            })
        } catch (e) {
            if (e.name === "NotFoundError") return null
        }
        return null
    }

    async updateAnnotations(mediaFilename, mediaId, annotations) {
        this.mediaFiles[mediaFilename].annotations = annotations
        const annotationsFileHandle = await this.getAnnotationsFileHandle(mediaId, true)
        const strippedAnnotations = Annotation.stripToPersist(annotations)
        await LocalStorage.writeFile(annotationsFileHandle, JSON.stringify(strippedAnnotations))
        this.notifyListeners()
    }

    async updateTranscript(mediaFilename, mediaId, transcript) {
        this.mediaFiles[mediaFilename].transcript.content = transcript
        const vttString = Transcript.toVTTString(transcript)
        const transcriptFileHandle = await this.getTranscriptFileHandle(mediaId, true)
        await LocalStorage.writeFile(transcriptFileHandle, vttString)
        this.notifyListeners()
    }

    async getAnnotationsFileHandle(mediaID, shouldCreate) {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const notesDir = await dingDir.getDirectoryHandle(NOTES_SUBDIR, { create: true })
        try {
            return await notesDir.getFileHandle(mediaID + ".notes.json", {
                create: shouldCreate || false,
            })
        } catch (e) {
            if (e.name === "NotFoundError") return null
        }
        return null
    }

    static readFileAsync(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
            reader.readAsText(file)
        })
    }

    /**
     * Returns filename to peaks.json
     */
    async writePeaks(mediaFile, peaksData) {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const peaksDir = await dingDir.getDirectoryHandle(PEAKS_SUBDIR, { create: true })
        const filename = mediaFile.id + ".json"
        const peaksFile = await peaksDir.getFileHandle(filename, { create: true })
        await LocalStorage.writeFile(peaksFile, JSON.stringify(peaksData))
        this.scanForMediaFiles()
    }

    static async writeFile(fileHandle, contents) {
        const writable = await fileHandle.createWritable()
        await writable.write(contents)
        await writable.close()
    }

    makeFilePath = (file, pathArray) => {
        if (pathArray.length === 0) return file.name
        return pathArray.join("/") + "/" + file.name
    }

    async scanDir(dir, path, recursive = false) {
        const files = []

        const sortFunction = (a, b) => {
            // Both folders or both files, sort by name
            if (a.kind === "directory" && b.kind === "directory")
                return a.name.localeCompare(b.name)
            // Only one is folder, sort by that one
            if (a.kind === "directory") return -1
            if (b.kind === "directory") return 1
            // Both files, sort by name
            return a.name.localeCompare(b.name)
        }

        const toArray = async (asyncIterator) => {
            const arr = []
            for await (const i of asyncIterator) arr.push(i)
            return arr
        }

        const values = await toArray(dir.values())
        values.sort(sortFunction)
        for await (const entry of values) {
            const nextPath = dir === this.storageBaseDir ? [] : [...path, dir.name]
            entry.path = nextPath
            // Do some filtering
            if (entry.name.startsWith(".")) continue
            if (entry.kind === "directory") {
                entry.iconType = "directory"
                if (recursive) {
                    const subdirfiles = await this.scanDir(entry, nextPath, true)
                    files.push(...subdirfiles)
                } else {
                    files.push(entry)
                }
            }
            const extension = entry.name.includes(".")
                ? entry.name.split(".").pop().toLowerCase()
                : ""
            entry.path = nextPath // Array format
            entry.filePath = this.makeFilePath(entry, nextPath) // String format
            if (["mp3", "m4a", "ogg", "wav"].includes(extension)) {
                entry.iconType = "audio"
                files.push(entry)
                continue
            }
            if (["mp4", "mov", "ogv"].includes(extension)) {
                entry.iconType = "video"
                files.push(entry)
                continue
            }
        }

        return files
    }

    async directoryTree() {
        const populateChildren = async (dir) => {
            const files = await this.scanDir(dir, dir.path)
            for await (const file of files) {
                if (file.kind === "directory") await populateChildren(file)
            }
            dir.children = files
            return files
        }
        return await populateChildren(this.storageBaseDir)
    }

    async scanForMediaFiles() {
        this.mediaFiles = {}
        if (!this.storageBaseDir) return
        const allFiles = await this.scanDir(this.storageBaseDir, [], true)
        const files = allFiles.filter((f) => f.kind !== "directory")
        const mediaFiles = await Promise.all(
            files.map(async (f) => {
                const mf = await this.loadMediaFile(f.filePath)
                await mf.loadFiles(this)
                return mf
            }),
        )
        mediaFiles.forEach((mf) => (this.mediaFiles[mf.filename] = mf))
        this.notifyListeners()
    }

    async loadMediaFile(pathString) {
        if (pathString.length === 0) return null
        const [handle, pathArray] = await this.loadFileFromPath(pathString)
        const id = LocalStorage.getIdForFile(handle, pathArray)
        return new MediaFile({ id: id, path: pathArray, fileHandle: handle, filename: pathString })
    }

    /**
     *
     * @param {FileSytemFileHandle} fileHandle
     * @param {string[]} path
     * @returns String eg. "dir1__dir2__dir3__filename.ext"
     */
    static getIdForFile(fileHandle, path) {
        let builder = ""
        if (path.length > 0) {
            // If path.length === 0 then join returns empty string.
            // Prepend __ for consistency
            builder += "__"
            builder += path.join("__")
        }
        return builder + "__" + fileHandle.name
    }

    /**
     * Returns file
     * @param {*} pathString
     */
    async loadFileFromPath(pathString) {
        if (!pathString || pathString.length === 0) return [null, null]
        const path = pathString.split("/").reverse()
        const pathArray = []
        let handle = this.storageBaseDir
        while (path.length > 1) {
            const dir = path.pop()
            pathArray.push(dir)
            handle = await handle.getDirectoryHandle(dir)
        }
        handle = await handle.getFileHandle(path.pop())
        return [handle, pathArray]
    }

    getMediaFile(pathString) {
        return this.mediaFiles[pathString]
    }
}
