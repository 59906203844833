import Typography from "@mui/material/Typography"
import * as React from "react"
import HomepageOutline from "./HomepageOutline"

export default function HelpPage({ theme }) {
    return (
        <HomepageOutline theme={theme}>
            <Typography variant="h5" gutterBottom>
                <b>Storage</b>
            </Typography>
            <Typography component="p" gutterBottom>
                Ding! works with files stored on your device. Your files and notes are never
                uploaded, copied or stored elsewhere by Ding!, except when you use the transcription
                service (details below).
            </Typography>
            <Typography component="p" gutterBottom>
                Many recorded conversations are sensitive, confidential, or subject to ethics
                considerations. That's why Ding! wants you to retain complete custody of your data,
                on your device: it's easier and more secure if you don't need to worry about how we
                handle it. Please note that this means keeping your data backed-up and secure
                remains your responsibility.
            </Typography>
            <Typography component="p" gutterBottom sx={{ ml: 4 }}>
                <b>Opening a folder</b>
                <br />
                When choosing which folder to open, the confirmation button may read as "select" or
                "upload". This is a cosmetic difference depending on your web browser, and in either
                case, Ding! does not upload any files contained (except when using the transcription
                service).
            </Typography>
            <Typography component="p" gutterBottom sx={{ ml: 4 }}>
                <b>The 'ding' subfolder</b>
                <br />
                When you open a folder, Ding! creates a subfolder to store your notes, transcripts,
                and related data. The ding subfolder is not intended to be accessed modified
                directly by users.
            </Typography>
            <Typography component="p" gutterBottom sx={{ ml: 4 }}>
                <b>Granting permissions</b>
                <br />
                Ding! needs you to grant permission to access and modify files within the folders
                you choose to open.
            </Typography>
            <br />

            <Typography variant="h5" gutterBottom>
                <b>Collaboration and sharing</b>
            </Typography>
            <Typography component="p" gutterBottom>
                Ding! doesn't have any collaboration functionality yet. If you need to work with
                other people, you can share your Ding! folders using a cloud storage app like
                Dropbox or Google Drive. However, only one person at a time should access a Ding!
                folder, as concurrent editing by multiple users will result in data loss.
            </Typography>
            <br />

            <Typography variant="h5" gutterBottom>
                <b>Transcription service</b>
            </Typography>
            <Typography component="p" gutterBottom>
                Ding! offers a prepaid, per-minute AI transcription service.
            </Typography>
            <Typography component="p" gutterBottom sx={{ ml: 4 }}>
                <b>Prepaid transcription credits</b>
                <br />
                Transcription services can be purchased in-app. Upon payment, Ding! will issue you
                an access code linked to the credits you paid for. You may share the access code
                (eg. with colleagues), if you wish for other people to use those transcription
                minutes.
            </Typography>
            <Typography component="p" gutterBottom sx={{ ml: 4 }}>
                <b>Merchant of Record</b>
                <br />
                We use a third-party service to handle payments.
            </Typography>
            <Typography component="p" gutterBottom sx={{ ml: 4 }}>
                <b>Data retention</b>
                <br />
                Your media is uploaded to Ding! and third-party providers for the purpose of
                creating an AI transcript. Your files and the subsequent transcript are deleted from
                our systems shortly after the transcript appears in your Ding! app. Your content is
                not retained in any form, or used for training AI models.
            </Typography>
            <br />
        </HomepageOutline>
    )
}
