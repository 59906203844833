import * as React from "react"
import HomepageOutline from './Homepage/HomepageOutline'
import { useRouteError } from "react-router"

export default function ErrorElement({theme}) {

    const error = useRouteError();
    console.log(error)

    return(
        <HomepageOutline theme={theme}>
            <h3>Dang! Something went wrong. {error.status}</h3>
            {error.data}
        </HomepageOutline>
    )
};