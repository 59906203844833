import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid2"
import IconButton from "@mui/material/IconButton"
import Modal from "@mui/material/Modal"
import { grey } from "@mui/material/colors"
import { X } from "@phosphor-icons/react"
import * as React from "react"

export default function AlertModal({
    isOpen,
    title,
    width = "600px",
    height = "auto",
    message,
    handleClose,
    positiveButtonLabel,
    handleConfirm,
    buttons,
}) {
    if (!buttons) {
        buttons = (
            <Box sx={{ pb: 3, px: 3, display: "flex", flexDirection: "column", alignItems: "end" }}>
                <Box>
                    <Button
                        disableElevation
                        variant="outlined"
                        sx={{ mr: 2 }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button disableElevation variant="contained" onClick={handleConfirm}>
                        {positiveButtonLabel}
                    </Button>
                </Box>
            </Box>
        )
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid
                container
                direction="column"
                sx={{
                    size: "grow",
                    background: "white",
                    color: grey[700],
                    width: width,
                    height: height,
                    minWidth: "600px",
                    maxHeight: "90%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: 1,
                    overflow: "hidden",
                    userSelect: "none",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        px: 3,
                        py: 2,
                        backgroundColor: grey[200],
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <h2 style={{ margin: 0, lineHeight: 1, flexGrow: 1 }}>{title}</h2>
                    <IconButton onClick={handleClose}>
                        <X size={20} weight="bold" />
                    </IconButton>
                </Box>

                <Divider />

                {message}

                <Grid>{buttons}</Grid>
            </Grid>
        </Modal>
    )
}
