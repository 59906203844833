import * as React from "react"

import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid2"
import Link from "@mui/material/Link"
import { grey } from "@mui/material/colors"
import ThemeProvider from "@mui/system/ThemeProvider"
import { Link as RouterLink } from "react-router"

export default function HomepageOutline({ theme, children }) {
    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container maxWidth="md" sx={{ mb: 16, mt: 0, color: grey[800] }}>
                    <Grid container spacing={2} sx={{ mt: 4, mb: 8 }}>
                        <Grid>
                            <Link variant="h4" color="textPrimary" underline="none" href="/">
                                <b>Ding!</b>
                            </Link>
                        </Grid>

                        <Grid display="flex" size="grow" alignItems="center" justifyContent="end">
                            <Link variant="button" underline="hover" href="/about" sx={{ mr: 4 }}>
                                About
                            </Link>
                            <Link variant="button" underline="hover" href="/pricing" sx={{ mr: 4 }}>
                                Pricing
                            </Link>
                            <Link variant="button" underline="hover" href="/help" sx={{ mr: 4 }}>
                                Help
                            </Link>
                        </Grid>

                        <Grid style={{ alignContent: "center" }}>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to="/app"
                                disableElevation
                            >
                                Open the App
                            </Button>
                        </Grid>
                    </Grid>

                    {children}

                    <Grid container spacing={2} sx={{ pt: 8, pb: 4 }}>
                        <Link
                            variant="button"
                            underline="hover"
                            href="/terms"
                            sx={{ mr: 4, color: grey[500] }}
                        >
                            Terms
                        </Link>
                        <Link
                            variant="button"
                            underline="hover"
                            href="/privacy"
                            sx={{ mr: 4, color: grey[500] }}
                        >
                            Privacy
                        </Link>
                    </Grid>
                </Container>
            </ThemeProvider>
        </React.StrictMode>
    )
}
