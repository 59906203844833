import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { grey } from "@mui/material/colors"
import * as React from "react"
import AddCreditFlow from "../Common/AddCreditFlow"
import OrderOverview from "./OrderOverview"

const Mode = {
    Overview: "overview",
    AddCredit: "add-credit",
}

export default function OrderTranscriptionModal({
    isOpen,
    selectedMedia,
    mediaDuration,
    handleClose,
    handleConfirm,
}) {
    const [mode, setMode] = React.useState(Mode.Overview)
    const [recalculateTrigger, setRecalculateTrigger] = React.useState(false)

    const startAddCreditFlow = () => {
        setMode(Mode.AddCredit)
    }

    const onCreditAdded = () => {
        setRecalculateTrigger(!recalculateTrigger)
        setMode(Mode.Overview)
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    background: "white",
                    color: grey[700],
                    width: "600px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: 1,
                    overflow: "hidden",
                    userSelect: "none",
                }}
            >
                {mode === Mode.Overview && (
                    <OrderOverview
                        selectedMedia={selectedMedia}
                        mediaDuration={mediaDuration}
                        handleClose={handleClose}
                        handleConfirm={handleConfirm}
                        handleAddCredit={startAddCreditFlow}
                        recalculateTrigger={recalculateTrigger}
                    />
                )}

                {mode === Mode.AddCredit && (
                    <AddCreditFlow
                        handleClose={() => {
                            setMode(Mode.Overview)
                            handleClose()
                        }}
                        onCreditAdded={onCreditAdded}
                    />
                )}
            </Box>
        </Modal>
    )
}
