import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Grid from "@mui/material/Grid2"
import { grey } from "@mui/material/colors"
import { Folder, PlusCircle } from "@phosphor-icons/react"
import * as React from "react"
import { CreditCodes } from "../../model/CreditCodes"
import ForgetCreditModal from "../Common/ForgetCreditModal"
import AddCreditModal from "./AddCreditModal"
import CreditPopover from "./CreditPopover"
import FileTable from "./FileTable"

export default function FolderOverviewComponent({ localStorage, directory }) {
    // const [allMediaFiles, setAllMediaFiles] = React.useState([])
    const [creditPopoverAnchorEl, setCreditPopoverAnchorEl] = React.useState(null)
    const [remainingMinutes, setRemainingMinutes] = React.useState(0)
    const [remainingCreditList, setRemainingCreditList] = React.useState({}) // creditId -> minutes

    const jobManager = localStorage.jobManager
    const jobsList = React.useSyncExternalStore(jobManager.subscribe, jobManager.getSnapshot)
    const [jobsByFilename, setJobsByFilename] = React.useState({})
    React.useEffect(() => {
        // TODO: see if jobs-by-filename is the only usage and return that by default from JobManager
        // TODO: handle multiple jobs (attempts) per filename
        const updatedJobsByFilename = {}
        for (const job of Object.values(jobsList)) {
            updatedJobsByFilename[job.mediaFilename] = job
        }
        setJobsByFilename(updatedJobsByFilename)
    }, [jobsList])

    const allMediaFiles = React.useSyncExternalStore(
        localStorage.subscribe,
        localStorage.getSnapshot,
    )

    const updateRemainingMinutes = async () => {
        var remainingMinutes = 0
        const remainingCreditsList = await CreditCodes.getRemainingCredits()
        setRemainingCreditList(remainingCreditsList)
        Object.values(remainingCreditsList).map((m) => (remainingMinutes += m))
        setRemainingMinutes(remainingMinutes)
    }

    const remainingCreditCodes = React.useSyncExternalStore(
        CreditCodes.subscribe,
        CreditCodes.getSnapshot,
    )
    React.useEffect(() => {
        updateRemainingMinutes()
    }, [remainingCreditCodes])

    const handleCreditPopoverClick = (e) => {
        setCreditPopoverAnchorEl(e.currentTarget)
    }
    const handleCreditPopoverClose = () => {
        setCreditPopoverAnchorEl(null)
    }

    /**
     * Add Credit Modal
     */
    const [addCreditModalOpen, setAddCreditModalOpen] = React.useState(false)

    const handleAddCredit = () => {
        // updateRemainingCredit()
        setAddCreditModalOpen(false)
    }

    /**
     * Confirm Credit Remove AlertModal
     */
    const [confirmCreditRemoveModalOpen, setConfirmCreditRemoveModalOpen] = React.useState(false)
    const [creditIdToRemove, setCreditIdToRemove] = React.useState()

    const handleCreditRemove = (creditId) => {
        setConfirmCreditRemoveModalOpen(true)
        setCreditIdToRemove(creditId)
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <AddCreditModal
                isOpen={addCreditModalOpen}
                handleClose={() => setAddCreditModalOpen(false)}
                handleAddCredit={handleAddCredit}
            />
            <ForgetCreditModal
                isOpen={confirmCreditRemoveModalOpen}
                handleClose={() => setConfirmCreditRemoveModalOpen(false)}
                creditId={creditIdToRemove}
                remainingCreditList={remainingCreditList}
            />
            <CreditPopover
                creditPopoverAnchorEl={creditPopoverAnchorEl}
                handleCreditPopoverClose={handleCreditPopoverClose}
                remainingCreditList={remainingCreditList}
                handleCreditRemove={handleCreditRemove}
            />

            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    minHeight: "64px",
                    height: "64px",
                    px: 4,
                    backgroundColor: grey[50],
                }}
            >
                <Grid>
                    <h2
                        style={{
                            margin: 0,
                            lineHeight: 1,
                            color: grey[700],
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Folder style={{ marginRight: "24px" }} /> {directory}
                    </h2>
                </Grid>

                <Grid>
                    <ButtonGroup variant="outlined" aria-label="Basic button group">
                        <Button onClick={handleCreditPopoverClick}>
                            {remainingMinutes} minutes credit
                        </Button>
                        <Button
                            disableElevation
                            sx={{ ml: 2 }}
                            variant="contained"
                            onClick={() => setAddCreditModalOpen(true)}
                            startIcon={<PlusCircle weight="bold" />}
                        >
                            Add credit
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <FileTable
                mediaFiles={allMediaFiles}
                jobsByFilename={jobsByFilename}
                jobManager={jobManager}
            />
        </Box>
    )
}
