import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import * as React from "react"
import HomepageOutline from "./HomepageOutline"

export default function AboutPage({ theme }) {
    return (
        <HomepageOutline theme={theme}>
            <Box sx={{ mx: 4 }}>
                <Typography variant="h6">Hi, I'm Mitch.</Typography>
                <br />

                <Typography variant="h6">
                    There is immense value in sitting down and listening back to conversations. It
                    helps thoughtful humans comprehend things in ways that AI summaries cannot.
                    Ding! is a tool to make that process as easy and streamlined as possible.
                </Typography>
                <br />

                <Typography variant="h6">
                    I made Ding! to help me manage hundreds of hours of video interviews. I wanted
                    to quickly jump between and synthesise, important pieces of conversation. I
                    wanted a transcription service unconstrained by subscription fees or monthly
                    limits.
                </Typography>
                <br />

                <Typography variant="h6">
                    Today, Ding! helps me organise the interviews I do as a freelance writer. I'm
                    sharing Ding! to help others with their own writing and research. Please drop me
                    a line with suggestions for how Ding! can be more helpful for you.
                </Typography>
            </Box>
        </HomepageOutline>
    )
}
