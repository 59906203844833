import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import FormHelperText from "@mui/material/FormHelperText"
import Grid from "@mui/material/Grid2"
import InputAdornment from "@mui/material/InputAdornment"
import OutlinedInput from "@mui/material/OutlinedInput"
import { grey } from "@mui/material/colors"
import { type CheckoutOpenLineItem, type Paddle, initializePaddle } from "@paddle/paddle-js"
import * as React from "react"
import PriceTable from "../Common/PriceTable"

export default function Checkout({ onPaddleCheckoutComplete }) {
    const PADDLE_ENV = "sandbox"
    const AUTH_TOKEN = "test_ca12e29f3fb864f906685b9f5f7"
    const T1_HOURS_PRODUCT = "pri_01jdrscx0w5rtk67t9vpzed4ww"
    const T2_HOURS_PRODUCT = "pri_01jdrsdk4nyc3a31w6bd4z6c76"

    // Create a local state to store Paddle instance
    const [paddle, setPaddle] = React.useState<Paddle>()
    const [hoursOrdered, setHoursOrdered] = React.useState<number>(10) // see defaultValue below
    const [hoursInputIsValid, setHoursInputIsValid] = React.useState<boolean>(true)
    const [hoursHelperText, setHoursHelperText] = React.useState("$20 + tax")

    const onPaddleEvent = (event) => {
        if (event.name === "checkout.completed") {
            const txnId = event.data.transaction_id
            const customerEmail = event.data.customer.email
            onPaddleCheckoutComplete(txnId, customerEmail)
        }
    }

    // Download and initialize Paddle instance from CDN
    React.useEffect(() => {
        initializePaddle({ environment: PADDLE_ENV, token: AUTH_TOKEN }).then(
            (paddleInstance: Paddle | undefined) => {
                if (paddleInstance) {
                    paddleInstance.Update({
                        eventCallback: onPaddleEvent,
                    })
                    setPaddle(paddleInstance)
                }
            },
        )
    }, [])

    // Callback to open a checkout
    const openCheckout = () => {
        const hours = hoursOrdered
        const items: CheckoutOpenLineItem[] = []

        if (hours <= 50) {
            items.push({ priceId: T1_HOURS_PRODUCT, quantity: hours })
        } else {
            items.push({ priceId: T1_HOURS_PRODUCT, quantity: 50 })
            items.push({ priceId: T2_HOURS_PRODUCT, quantity: hours - 50 })
        }
        paddle?.Checkout.open({
            items: items,
        })
    }

    const calculateCost = (hours) => {
        if (hours <= 50) return hours * 2
        hours -= 50
        return 100 + hours
    }

    const validateHours = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        const isValid = Number.parseInt(val) >= 5
        if (!isValid) {
            setHoursHelperText("Must be 5 or more")
        } else {
            setHoursHelperText("$" + calculateCost(val) + " plus tax")
        }
        setHoursInputIsValid(isValid)
        setHoursOrdered(Number.parseInt(val))
    }

    return (
        <Box>
            <Card variant="outlined">
                <Grid container>
                    <Grid size="grow" sx={{ p: 2, background: grey[100] }}>
                        <PriceTable textColor={grey[800]} />
                    </Grid>

                    <Grid sx={{ p: 2, alignContent: "flex-end" }}>
                        <OutlinedInput
                            size="small"
                            error={!hoursInputIsValid}
                            id="hoursToBuy"
                            sx={{ width: "120px" }}
                            defaultValue="10"
                            onChange={validateHours}
                            endAdornment={<InputAdornment position="end">hours</InputAdornment>}
                        />
                        <FormHelperText>{hoursHelperText}</FormHelperText>
                        <br />
                        <br />
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ height: 36, alignSelf: "end" }}
                            onClick={() => openCheckout()}
                        >
                            Purchase hours
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    )
}
