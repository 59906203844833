import LocalStorage from "./LocalStorage"

export class Annotation {
    // {
    //     "name":"Phyllis",
    //     "mediaName":"Phyllis/PhyllisCombined.m4a",
    //     "start":"1371.23",
    //     "comment":"Maurice Connors another woman after their mother, who Phyllis met at Rocky Creek Hospital.",
    //     "tags":[]
    // }

    static reactKeyCounter = 0

    name        // TODO: Unnecessary?
    mediaName   // TODO: Unnecessary?
    start
    comment
    tags = []
    reactKey

    constructor({ name, mediaName, start, comment, tags }) {
        this.name = name            // TODO: unnecsesary??
        this.mediaName = mediaName  // TODO: unnecessary??
        this.start = start
        this.comment = comment || ""
        this.tags = tags || []
        this.reactKey = Annotation.reactKeyCounter++
    }

    // Returns array of Annotation
    static async readFromFile(annotationsFileHandle) {
        if (annotationsFileHandle === null) {
            return []
        }
        const annotationsFile = await annotationsFileHandle.getFile()
        const annotationsFileData = await LocalStorage.readFileAsync(annotationsFile)

        if (annotationsFileData === "") {
            return []
        }
        const fileContents = JSON.parse(annotationsFileData)
        const annotations = fileContents.map((a) => new Annotation(a))
        return annotations
    }

    // Take a map of annotation objects and strip unnecessary vars
    static stripToPersist(annotations) {
        return annotations.map( a => {
            return({
                start: a.start,
                comment: a.comment,
                tags: a.tags
            })
        })
    }
    
}
