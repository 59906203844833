import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import { Check, HandPointing } from "@phosphor-icons/react"
import * as React from "react"
import PriceTable from "../Common/PriceTable"
import HomepageOutline from "./HomepageOutline"

export default function AboutPage({ theme }) {
    return (
        <HomepageOutline theme={theme}>
            <Grid container spacing={2}>
                <Grid
                    sx={{
                        borderRadius: 2,
                        borderColor: "grey.200",
                        borderWidth: "1px",
                        borderStyle: "solid",
                    }}
                    xs={6}
                    size="grow"
                >
                    <Box sx={{ p: 2 }}>
                        <Typography variant="h6">
                            <b>Ding! is free to use</b>
                        </Typography>

                        <ul style={{ listStyle: "none", paddingLeft: "0.5rem" }}>
                            <li>
                                <Check weight="bold" style={{ marginRight: "1rem" }} />
                                Quickly navigate across your recordings
                            </li>
                            <li>
                                <Check weight="bold" style={{ marginRight: "1rem" }} />
                                Create a time-coded topic index with tags
                            </li>
                            <li>
                                <Check weight="bold" style={{ marginRight: "1rem" }} />
                                Add time-coded notes
                            </li>
                            <li>
                                <Check weight="bold" style={{ marginRight: "1rem" }} />
                                No sign-up necessary
                            </li>
                        </ul>
                    </Box>
                </Grid>

                <Grid
                    sx={{
                        borderRadius: 2,
                        borderColor: "grey.200",
                        borderWidth: "1px",
                        borderStyle: "solid",
                    }}
                    xs={6}
                    size="grow"
                >
                    <Box sx={{ pt: 2, px: 2, pb: 0 }}>
                        <Typography variant="h6">
                            <b>Prepaid per-minute transcription</b>
                        </Typography>

                        <ul style={{ listStyle: "none", paddingLeft: "0.5rem" }}>
                            <li>
                                <Check weight="bold" style={{ marginRight: "1rem" }} />
                                Rapid text search across conversations
                            </li>
                            <li>
                                <Check weight="bold" style={{ marginRight: "1rem" }} />
                                Accurate transcripts deliverered within minutes
                            </li>
                            <li>
                                <Check weight="bold" style={{ marginRight: "1rem" }} />
                                Content not retained or used for AI training
                            </li>
                            <li>
                                <Check weight="bold" style={{ marginRight: "1rem" }} />
                                Support for 30+ languages
                            </li>
                        </ul>
                    </Box>
                    <PriceTable />
                    <Box sx={{ p: 2, display: "flex" }}>
                        <HandPointing size={32} style={{ marginRight: "1rem" }} />
                        Your purchase supports an independent software developer
                    </Box>
                </Grid>
            </Grid>
        </HomepageOutline>
    )
}
