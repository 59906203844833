import * as React from "react"

import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import ThemeProvider from "@mui/system/ThemeProvider"
import { Outlet, useNavigate } from "react-router"
import { CreditCodes } from "../../model/CreditCodes"
import LocalStorage from "../../model/LocalStorage"
import "./app.css"

import MiniDrawer from "./Drawer.js"

export default function EditorApp({ theme }) {
    const [localStorage, setLocalStorage] = React.useState(new LocalStorage())
    const [canDoInitialRender, setCanDoInitialRender] = React.useState(false)
    const [directory, setDirectory] = React.useState("")

    const navigate = useNavigate()

    CreditCodes.init()

    const tryOpenPersistedBaseDir = async () => {
        const gotPersistedBaseDir = await localStorage.tryOpenPersistedBaseDir()
        if (gotPersistedBaseDir) {
            setDirectory(localStorage.getCurrentWorkingDirectory())
        }
        setCanDoInitialRender(true)
    }

    const onLoadFolderClicked = async (e) => {
        const gotStorageBaseDir = await localStorage.trySelectStorageBaseDir()
        if (gotStorageBaseDir) {
            setDirectory(localStorage.getCurrentWorkingDirectory())
        }
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    React.useEffect(() => {
        tryOpenPersistedBaseDir()
    }, [])

    const onFolderClosed = (e) => {
        localStorage.reset()
        setDirectory(null)
    }

    if (!canDoInitialRender) {
        return null
    }

    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <Box width="100%" sx={{ display: "flex" }}>
                    <CssBaseline />
                    {directory && (
                        <MiniDrawer
                            localStorage={localStorage}
                            onLoadFolderClicked={onLoadFolderClicked}
                            onFolderClosed={onFolderClosed}
                        />
                    )}
                    <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default" }}>
                        <Outlet context={[localStorage, directory, onLoadFolderClicked]} />
                    </Box>
                </Box>
            </ThemeProvider>
        </React.StrictMode>
    )
}
