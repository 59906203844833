import { Box } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid2"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import ContentEditable from "../../Common/ContentEditablePlaintext.tsx"
import * as React from "react"
import { TimeUtil } from "../../../model/TimeUtil"
import { grey, blue } from "@mui/material/colors"
import { CaretDown, Timer } from "@phosphor-icons/react"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"

export default function AnnotationEditor({
    selectedAnnotation,
    allTags,
    mediaCursor,
    onCancelClicked,
    onDeleteClicked,
    onSaveClicked,
    onTimecodeClick,
    index,
    isEditing,
}) {
    const [annotation, setAnnotation] = React.useState(selectedAnnotation)

    function onTagsChange(event, selectedTags) {
        // We added an empty string tag at the start which we use to render the timestamp chip
        // Filter it out before saving
        const filteredTags = selectedTags.filter((t) => t)
        setAnnotation({
            ...annotation,
            tags: filteredTags,
        })
    }

    function onCommentChange(event) {
        const comment = event.target.value
        setAnnotation({
            ...annotation,
            comment: comment,
        })
    }

    function updateTime() {
        setAnnotation({
            ...annotation,
            start: mediaCursor,
        })
    }

    const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
        "& .MuiFormControl-root": {
            backgroundColor: "transparent",
        },
        "& .MuiInput-root::before": {
            border: 0,
        },
        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before": {
            border: 0,
        },
        "& .MuiInput-root:hover::before": {
            border: 0,
        },
        "& .MuiInput-root::after": {
            border: 0,
        },
        "& .MuiAutocomplete-root:hover::after": {
            border: 0,
        },
        "& .MuiInputBase-input": {
            paddingBottom: "0 !important",
            fontSize: "14px",
            marginLeft: "8px",
            cursor: isEditing ? "" : "pointer",
        },
        "& .MuiAutocomplete-root": {
            cursor: isEditing ? "" : "pointer",
        },
        "& .MuiInputBase-root": {
            cursor: isEditing ? "" : "pointer",
        },
    }))

    function onClick(e) {
        if (!isEditing) {
            onTimecodeClick(e)
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null)
    const timestampMenuOpen = Boolean(anchorEl)
    const timestampMenuClick = (event) => {
        if (!isEditing) return
        setAnchorEl(event.currentTarget)
    }

    return (
        <Box
            className={`annotation ${isEditing && "isEditing"}`}
            onClick={onClick}
            data-timecode={annotation.start}
            data-index={index}
            sx={{
                p: 1,
                cursor: isEditing ? "" : "pointer",
                backgroundColor: isEditing ? grey[100] : "",
                border: 1,
                borderRadius: 1,
                borderColor: isEditing ? grey[300] : "#ffffff00",
                mb: 1,
            }}
        >
            <Menu
                open={timestampMenuOpen}
                anchorEl={anchorEl}
                sx={{ marginTop: "2px" }}
                onClose={() => setAnchorEl(null)}
            >
                <MenuList dense sx={{ p: 0 }}>
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null)
                            updateTime()
                        }}
                    >
                        <ListItemIcon>
                            <Timer size={16} weight="bold" />
                        </ListItemIcon>
                        <ListItemText>
                            Set time to {TimeUtil.secondsToDisplayFormat(mediaCursor)}
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <Grid container direction="row">
                <Grid>
                    <Box>
                        <Typography
                            sx={[
                                isEditing && { background: blue[100] },
                                isEditing && { "&:hover": { background: blue[200] } },
                            ]}
                            onClick={timestampMenuClick}
                            variant="caption"
                            style={{
                                marginRight: "6px",
                                marginBottom: "2px",
                                padding: "2px 4px 2px 4px",
                                opacity: "0.5",
                                fontWeight: "bold",
                                borderRadius: 4,
                                userSelect: "none",
                                paddingTop: "2px",
                                cursor: "pointer",
                            }}
                        >
                            {TimeUtil.secondsToDisplayFormat(annotation.start)}
                            <CaretDown
                                weight="bold"
                                style={{
                                    visibility: isEditing ? "visible" : "hidden",
                                    marginLeft: "4px",
                                    paddingTop: "1px",
                                    verticalAlign: "text-top",
                                }}
                            />
                        </Typography>
                    </Box>
                </Grid>
                <Grid size="grow">
                    <StyledAutocomplete
                        fullWidth
                        size="small"
                        multiple
                        freeSolo
                        readOnly={!isEditing}
                        options={allTags}
                        value={annotation.tags || []}
                        onChange={onTagsChange}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder={isEditing ? "Add (more) tags..." : ""}
                            />
                        )}
                        renderTags={(tagValues, getTagProps) =>
                            tagValues.map((option, index) => (
                                <Chip
                                    size="small"
                                    label={option}
                                    key={option}
                                    sx={{
                                        m: 0,
                                        backgroundColor: isEditing ? grey[200] : grey[100],
                                        color: isEditing ? grey[800] : grey[600],
                                    }}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    />
                </Grid>
            </Grid>

            <ContentEditable
                html={annotation.comment}
                onChange={onCommentChange}
                style={{
                    background: isEditing ? grey[200] : "",
                    outline: "none",
                    padding: "8px",
                    borderRadius: "4px",
                    whiteSpace: "pre-wrap",
                }}
                disabled={!isEditing}
            />

            {isEditing && (
                <Grid container sx={{ mt: 1, justifyContent: "end" }}>
                    <Button sx={{ mr: 1 }} size="small" onClick={onCancelClicked} disableElevation>
                        Cancel
                    </Button>
                    {!annotation.isNew ? (
                        <Button
                            sx={{ mr: 1 }}
                            size="small"
                            onClick={onDeleteClicked}
                            disableElevation
                        >
                            Delete
                        </Button>
                    ) : null}
                    <Button
                        disableElevation
                        size="small"
                        onClick={() => onSaveClicked(annotation)}
                        variant="contained"
                    >
                        Save
                    </Button>
                </Grid>
            )}
        </Box>
    )
}
