import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import * as React from "react"

export default function PriceTable({ backgroundColor = grey[100], textColor = grey[800] }) {
    return (
        <Table size="small">
            <TableBody sx={{ color: textColor }}>
                <TableRow sx={{ backgroundColor: backgroundColor }}>
                    <TableCell sx={{ display: "flex", alignItems: "baseline", color: "inherit" }}>
                        <Typography variant="h4" sx={{ float: "left" }}>
                            $2
                        </Typography>
                        <Typography>&nbsp;USD/hour</Typography>
                    </TableCell>
                    <TableCell sx={{ color: "inherit" }}>
                        <Typography>First 50 hours</Typography>
                        <Typography variant="caption">minimum purchase $10</Typography>
                    </TableCell>
                </TableRow>

                <TableRow sx={{ backgroundColor: backgroundColor }}>
                    <TableCell sx={{ display: "flex", alignItems: "baseline", color: "inherit" }}>
                        <Typography variant="h4" sx={{ float: "left" }}>
                            $1
                        </Typography>
                        <Typography>&nbsp;USD/hour</Typography>
                    </TableCell>
                    <TableCell sx={{ color: "inherit" }}>
                        <Typography>beyond 50 hours</Typography>
                        <Typography variant="caption">
                            <b>
                                <u>best value</u>
                            </b>
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow sx={{ backgroundColor: backgroundColor }}>
                    <TableCell colSpan={2} sx={{ color: "inherit" }}>
                        <Typography variant="caption">Per order, plus tax</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}
