import * as React from "react"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import { useNavigate, useOutletContext, useParams } from "react-router"
import OrderTranscriptionModal from "../OrderTranscription/OrderTranscriptionModal"
import { Annotation } from "../../model/Annotation"
import VideoJS from "../VideoJSComponent/VideoJSComponent"
import AnnotationPane from "./AnnotationPane/AnnotationPane"
import PeakGeneratorComponent from "./PeakGeneratorComponent"
import TranscriptView from "./Transcript/TranscriptViewComponent"
import "./interview.css"

export default function Interview() {
    const navigate = useNavigate()
    const [localStorage, directory, onLoadFolderClicked] = useOutletContext()

    const [selectedMedia, setSelectedMedia] = React.useState(null)

    const [mediaCursor, setMediaCursor] = React.useState(0)
    const [mediaCursorTimestamp, setMediaCursorTimestamp] = React.useState(0)
    const [startTime, setStartTime] = React.useState(0)
    const [vjsTrigger, setVjsTrigger] = React.useState(0) // A hack to trigger an effect (play at timestamp) on the videojs componenet without
    // any other state change
    const [transcriptAutoScroll, setTranscriptAutoScroll] = React.useState(false)

    const [transcript, setTranscript] = React.useState()
    const [annotations, setAnnotations] = React.useState([])
    const [selectedAnnotationIndex, setSelectedAnnotationIndex] = React.useState(-1)

    const allMediaFiles = React.useSyncExternalStore(
        localStorage.subscribe,
        localStorage.getSnapshot,
    )

    // Actively running transcription job
    const jobsList = React.useSyncExternalStore(
        localStorage.jobManager.subscribe,
        localStorage.jobManager.getSnapshot,
    )
    const [job, setJob] = React.useState()
    // biome-ignore lint/correctness/useExhaustiveDependencies:
    React.useEffect(() => {
        if (!selectedMedia) return
        const transcriptionJob = Object.values(jobsList).filter(
            (j) =>
                j.mediaFilename === selectedMedia.filename &&
                j.status !== "failed" &&
                j.status !== "cancelled",
        )[0]
        setJob(transcriptionJob)
    }, [jobsList])

    // Load file from route params
    const params = useParams()
    const loadFileFromParams = async () => {
        const filePathString = params["*"]
        const mediaFile = localStorage.getMediaFile(filePathString)
        if (!mediaFile) {
            // If you open a different directory and the same filepath exists, it'll stay open
            setSelectedMedia(null)
            navigate("/app")
            return
        }
        setSelectedMedia(mediaFile)
        setTranscript(mediaFile.transcript)
        setAnnotations(mediaFile.annotations)
    }
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    React.useEffect(() => {
        loadFileFromParams()
    }, [allMediaFiles, params])

    /**
     * OrderTranscriptionModal
     */
    const [orderTranscriptionModalOpen, setOrderTranscriptionModalOpen] = React.useState(false)
    const [mediaDuration, setMediaDuration] = React.useState()

    const onTranscribeClick = async () => {
        setOrderTranscriptionModalOpen(true)
    }

    const onTranscribeConfirm = () => {
        localStorage.jobManager.add(selectedMedia.filename)
        setOrderTranscriptionModalOpen(false)
    }

    const onCursorMove = (time) => {
        setMediaCursor(time.toFixed(2))
        setMediaCursorTimestamp(time)
    }

    const onAddVttClick = (e) => {
        selectedMedia.tryAddVttFile(localStorage, () => loadFileFromParams())
    }

    const onTranscriptWordClick = (e) => {
        // Add 0.01sec to timecode so current line is highlighted correctly.
        const timecode =
            Number.parseFloat(e.target.closest("[data-timecode]").getAttribute("data-timecode")) +
            0.01
        setTranscriptAutoScroll(false)
        setStartTime(timecode)
        setVjsTrigger(vjsTrigger + 1)
    }

    const newAnnotation = () => {
        // TODO (bug): two new annotations in a row - save previous new annotation
        const newAnnotation = new Annotation({ start: mediaCursor })
        newAnnotation.isNew = true
        setSelectedAnnotationIndex(-1)
        const newAnnotations = [...annotations, newAnnotation]
        setAnnotations(sortAnnotationsByTime(newAnnotations))
    }
    // Cannot consistently add a new annotation and set the selected index at the same time.
    // Instead, setSelectedAnnotationIndex = -1 when adding a new annotation
    // Then before next render, find the new annotation and setSelectedAnnotationIndex accordingly.
    if (selectedAnnotationIndex === -1) {
        annotations.map((a, index) => {
            if (a.isNew) setSelectedAnnotationIndex(index)
        })
    }

    const sortAnnotationsByTime = (annotations) => {
        annotations.sort((a, b) => a.start - b.start)
        return annotations
    }

    const updateTranscript = (transcriptContent) => {
        setTranscript({
            content: transcriptContent,
        })
        localStorage.updateTranscript(selectedMedia.filename, selectedMedia.id, transcriptContent)
    }

    const playAtTimecode = (timecode) => {
        setStartTime(timecode)
        setVjsTrigger(vjsTrigger + 1)
    }

    if (!selectedMedia) {
        return <div />
    }

    if (selectedMedia && !selectedMedia.peaksBlobURL) {
        return (
            <div
                className="InterviewComponent"
                style={{ display: "flex", height: "100%", maxWidth: "100%" }}
            >
                <PeakGeneratorComponent localStorage={localStorage} mediaFile={selectedMedia} />
            </div>
        )
    }

    return (
        <div
            className="InterviewComponent"
            style={{ display: "flex", height: "100%", maxWidth: "100%" }}
        >
            <OrderTranscriptionModal
                isOpen={orderTranscriptionModalOpen}
                handleConfirm={onTranscribeConfirm}
                selectedMedia={selectedMedia}
                mediaDuration={mediaDuration}
                handleClose={() => setOrderTranscriptionModalOpen(false)}
            />
            <Grid item container sx={{ flexGrow: 1, my: 2 }}>
                <Grid item xs={6} container direction="column">
                    <Grid
                        item
                        sx={{
                            mx: 2,
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: 2,
                            borderColor: "grey.200",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            backgroundColor: "grey.50",
                            overflow: "hidden",
                        }}
                    >
                        <VideoJS
                            vjsTrigger={vjsTrigger}
                            onCursorMove={onCursorMove}
                            setTranscriptAutoScroll={setTranscriptAutoScroll}
                            media={selectedMedia}
                            reportMediaDuration={setMediaDuration}
                            startTime={startTime}
                        />
                        <Divider />
                        <TranscriptView
                            transcript={transcript}
                            filename={selectedMedia.filename}
                            mediaDuration={mediaDuration}
                            mediaCursorTimestamp={mediaCursorTimestamp}
                            autoScroll={transcriptAutoScroll}
                            onAddVttClick={onAddVttClick}
                            onTranscribeClick={onTranscribeClick}
                            onTranscriptWordClick={onTranscriptWordClick}
                            job={job}
                            jobManager={localStorage.jobManager}
                            updateTranscript={updateTranscript}
                            onAddNoteClick={newAnnotation}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container direction="column">
                    <AnnotationPane
                        localStorage={localStorage}
                        annotations={annotations}
                        setAnnotations={setAnnotations}
                        selectedAnnotationIndex={selectedAnnotationIndex}
                        setSelectedAnnotationIndex={setSelectedAnnotationIndex}
                        selectedMedia={selectedMedia}
                        playAtTimecode={playAtTimecode}
                        setTranscriptAutoScroll={setTranscriptAutoScroll}
                        sortAnnotationsByTime={sortAnnotationsByTime}
                        mediaCursor={mediaCursor}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
