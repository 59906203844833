import * as React from "react"
import { useOutletContext } from "react-router"
import FolderChooserComponent from "./FolderChooserComponent"
import FolderOverviewComponent from "./FolderOverviewComponent"

export default function DingScreen() {
    const [localStorage, directory, onLoadFolderClicked] = useOutletContext()

    async function onLoadFolder(onLoadFolderClicked) {
        await onLoadFolderClicked()
    }

    if (directory) {
        return (
            <FolderOverviewComponent
                localStorage={localStorage}
                directory={directory}
                style={{ display: "flex", flexDirection: "column" }}
            />
        )
    } else {
        return (
            <FolderChooserComponent onLoadFolderClicked={() => onLoadFolder(onLoadFolderClicked)} />
        )
    }
}
