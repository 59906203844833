import * as IdbKeyval from "idb-keyval"
const IDB_KEY_CREDIT_CODE = "billingReceiptCodes"

export class CreditCodes {
    static listeners = []

    // Hacks
    static storeVersion = 0
    static snapshotVersion = 0
    static snapshot = {}

    static subscribe = (listener) => {
        this.listeners = [...this.listeners, listener]
        return () => {
            this.listeners = this.listeners.filter((l) => l !== listener)
        }
    }

    static getSnapshot = () => {
        if (CreditCodes.storeVersion > CreditCodes.snapshotVersion) {
            CreditCodes.snapshotVersion = CreditCodes.storeVersion
        }
        return CreditCodes.snapshot
    }

    static notifyListeners() {
        CreditCodes.storeVersion++
        for (const listener of CreditCodes.listeners) {
            listener()
        }
    }

    static async init() {
        CreditCodes.takeSnapshot()
        CreditCodes.notifyListeners()
    }

    // Returns array of creditCodes
    static async takeSnapshot() {
        const codes = (await IdbKeyval.get(IDB_KEY_CREDIT_CODE)) || []
        CreditCodes.snapshot = codes.filter((c) => c) // Filter for all truty strings
    }

    static async addCreditCode(newReceiptCode) {
        // TODO: make it unique
        await IdbKeyval.update(IDB_KEY_CREDIT_CODE, (codes) => {
            if (!codes) codes = []
            const codeSet = new Set(codes)
            codeSet.add(newReceiptCode)
            return Array.from(codeSet)
        })
        await this.takeSnapshot()
        CreditCodes.notifyListeners()
    }

    static async removeCreditCode(creditCode) {
        await IdbKeyval.update(IDB_KEY_CREDIT_CODE, (codes) => {
            if (!codes) codes = []
            return codes.filter((value) => value !== creditCode)
        })
        await this.takeSnapshot()
        CreditCodes.notifyListeners()
    }

    // Returns { creditCode: minutes }
    static async getRemainingCredits() {
        var remainingCredits = {}
        try {
            const res = await fetch("/checkCredits/" + this.snapshot.join(","))
            remainingCredits = await res.json()
        } catch (e) {
            if (e.name === "SyntaxError") return {}
        }
        return remainingCredits
    }
}
