import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import { Receipt } from "@phosphor-icons/react"
import * as React from "react"

export default function CreditReceipt({ creditCode, creditMinutes }) {
    return (
        <Card variant="outlined" sx={{ m: 6, display: "flex" }}>
            <Grid container sx={{ flexGrow: 1 }}>
                <Grid container sx={{ p: 2, background: grey[100], alignContent: "center" }}>
                    <Receipt size={24} />
                </Grid>
                <Grid
                    size="grow"
                    container
                    sx={{ p: 2, background: grey[100], userSelect: "text", alignContent: "center" }}
                >
                    <Typography variant="button">
                        <b>{creditCode}</b>
                    </Typography>
                </Grid>
                <Grid sx={{ p: 2, alignContent: "center" }}>{creditMinutes} minutes</Grid>
            </Grid>
        </Card>
    )
}
