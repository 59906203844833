// Return dict of {tag => [Annotation]}
export default class Tags {
    static createTagIndex = async (localStorage) => {
        const tagIndex = {}

        const mediaFiles = localStorage.getSnapshot()

        Object.values(mediaFiles).forEach((mf) => {
            mf.annotations.forEach((annotation) => {
                // Add these properties to Annotation
                annotation.mediaFile = mf

                if (!annotation.tags) return

                annotation.tags.forEach((tag) => {
                    if (!tagIndex[tag]) {
                        tagIndex[tag] = [annotation]
                    } else {
                        tagIndex[tag].push(annotation)
                    }
                })
            })
        })

        return tagIndex
    }
}
