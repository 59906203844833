import Markdown from "markdown-to-jsx"
import * as React from "react"
import HomepageOutline from "./HomepageOutline"
import privacy from "./PRIVACY.md"

export default function PrivacyPage({ theme }) {
    const [markdown, setMarkdown] = React.useState("")

    React.useEffect(() => {
        fetch(privacy)
            .then((res) => res.text())
            .then((text) => setMarkdown(text))
    }, [])

    return (
        <HomepageOutline theme={theme}>
            <Markdown>{markdown}</Markdown>
        </HomepageOutline>
    )
}
