import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid2';
import { Copy, DownloadSimple, Printer } from "@phosphor-icons/react";
import copy from 'copy-to-clipboard';
import * as React from 'react';
import { TimeUtil } from "../../model/TimeUtil";
import AlertModal from '../Common/AlertModal'

export default function ExportTranscriptModal({isOpen, handleClose, filename, mediaDuration, transcript}) {

    const [useTimestamps, setUseTimestamps] = React.useState(true)
    const [extraSpace, setExtraSpace] = React.useState(true)

    if (!transcript)
        return null

    const renderTranscript = () => {
        const stringBuilder = []
        const cost = TimeUtil.roundUpSecondsToMinutes(mediaDuration)

        stringBuilder.push(`${filename} (${cost} minutes)`)
        stringBuilder.push("Transcript by dingnotes.com")
        stringBuilder.push("")
        stringBuilder.push("")
        for (const t of transcript.content) {
            let ts = ""
            if (useTimestamps) {
                ts = `[${t.startFormatted}]  `
            }
            stringBuilder.push(ts + t.text)
            if (extraSpace) {
                stringBuilder.push("")
            }
        }

        return stringBuilder.join("\n")
    }

    const copyToClipboard = () => {
        copy(renderTranscript(), {format: 'text/plain'})
    }

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([document.getElementById('txtDownloadInputElement').innerHTML], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "myFile.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      }

    const message = 
        <Grid container size="grow" direction="column" sx={{pt: 3, px: 3}}>

            <Grid>
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox size="small" checked={useTimestamps} onChange={ (e) => {setUseTimestamps(e.target.checked)} } />}
                        label="Include timestamps" />
                    <FormControlLabel
                        control={<Checkbox size="small" checked={extraSpace} onChange={ (e) => {setExtraSpace(e.target.checked)} } />}
                        label="Extra space" />
                </FormGroup>
            </Grid>

            <Grid size="grow" sx={{mt:4, mx: 4, overflow: 'scroll'}} >
                <Card variant="outlined">
                    <pre id="txtDownloadInputElement" style={{userSelect: 'text', whiteSpace: 'pre-wrap', padding: '32px', margin: 0}}>
                        {renderTranscript()}
                    </pre>
                </Card>
            </Grid>
        </Grid>

    const buttons = 
        <Box sx={{py: 3, px: 3, display: 'flex', flexDirection: 'row'}}>
            <Grid sx={{ flexGrow: 1 }}>
                <Button disableElevation variant="outlined" onClick={handleClose}>Cancel</Button>
            </Grid>
            <Grid>
                <Button disableElevation variant="outlined" startIcon={<Copy />} sx={{mr: 1}} onClick={ copyToClipboard }>Copy</Button>
                {/* <Button disableElevation variant="outlined" startIcon={<Printer />} sx={{mr: 1}} onClick={ () => {} }>Print</Button> */}
                <Button disableElevation variant="contained" startIcon={<DownloadSimple />} onClick={ downloadTxtFile }>Download .txt</Button>
            </Grid>
        </Box>

    return(
        <AlertModal
            isOpen={isOpen}
            handleClose={handleClose}
            width='60%'
            height='90%'
            minWidth='600px'
            message={message}
            buttons={buttons}
            title="Export Transcript"
        />
    )
}
