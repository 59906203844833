import * as React from "react"
import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import AnnotationEditor from "./AnnotationEditorComponent"
import { Annotation } from "../../../model/Annotation"
import Tags from "../../../model/Tags"
import { Notepad } from "@phosphor-icons/react"
import { grey } from "@mui/material/colors"

export default function AnnotationPane({
    localStorage,
    annotations,
    setAnnotations,
    selectedAnnotationIndex,
    setSelectedAnnotationIndex,
    setTranscriptAutoScroll,
    mediaCursor,
    playAtTimecode,
    selectedMedia,
    sortAnnotationsByTime,
}) {
    // TODO: tagSet holds the same information; can we just use that?
    const [tags, setTags] = React.useState([])
    const [tagSet, setTagSet] = React.useState(new Set())

    const loadAnnotation = (index) => {
        setSelectedAnnotationIndex(index)
    }

    const loadTags = async () => {
        const tagsIndex = await Tags.createTagIndex(localStorage)
        const tags = Object.keys(tagsIndex)
        setTags(tags)
        setTagSet(new Set(tags))
    }
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    React.useEffect(() => {
        loadTags()
    }, [])

    const onTimecodeClick = (e) => {
        const timecode = e.target.closest("[data-timecode]").getAttribute("data-timecode")
        const index = Number(e.target.closest("[data-index]").getAttribute("data-index"))
        setTranscriptAutoScroll(true)
        loadAnnotation(index)
        playAtTimecode(timecode)
    }

    const updateTagSet = (newTags) => {
        for (const tag in newTags) {
            tagSet.add(tag)
        }
        setTagSet(tagSet)
        setTags(Array.from(tagSet))
    }

    const onCancelClicked = () => {
        if (annotations[selectedAnnotationIndex].isNew) {
            onDeleteClicked()
        } else {
            setSelectedAnnotationIndex(-1)
        }
    }

    const onDeleteClicked = () => {
        annotations.splice(selectedAnnotationIndex, 1)
        updateAnnotations(annotations)
        setSelectedAnnotationIndex(-1)
    }

    const onSaveClicked = (updatedAnnotation) => {
        const annotation = new Annotation({
            start: updatedAnnotation.start,
            comment: updatedAnnotation.comment,
            tags: updatedAnnotation.tags,
        })
        updateTagSet(annotation.tags)
        annotations[selectedAnnotationIndex] = annotation
        updateAnnotations(sortAnnotationsByTime(annotations))
        setAnnotations(annotations)
        setSelectedAnnotationIndex(-1)
    }

    const updateAnnotations = (annotations) => {
        localStorage.updateAnnotations(selectedMedia.filename, selectedMedia.id, annotations)
    }

    if (annotations.length === 0) {
        return (
            <Grid
                container
                direction="column"
                color={grey[400]}
                sx={{
                    pr: 2,
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    userSelect: "none",
                }}
            >
                <Grid>
                    <Notepad size={36} />
                </Grid>
                <Grid>
                    <Typography variant="button">No notes</Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid sx={{ pr: 2, flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <div
                style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                }}
            >
                <div style={{ maxHeight: "1px" }}>
                    {annotations.map((a, index) => (
                        <AnnotationEditor
                            selectedAnnotation={a}
                            key={a.reactKey}
                            onCancelClicked={onCancelClicked}
                            onDeleteClicked={onDeleteClicked}
                            onSaveClicked={onSaveClicked}
                            mediaCursor={mediaCursor}
                            allTags={tags}
                            onTimecodeClick={onTimecodeClick}
                            isEditing={selectedAnnotationIndex === index}
                            index={index}
                        />
                    ))}
                </div>
            </div>
        </Grid>
    )
}
