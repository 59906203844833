import { VttCue, WebVtt } from "@audapolis/webvtt-writer"
import { TimeUtil } from "./TimeUtil"

export class TranscriptLine {
    constructor(startTime, endTime, text) {
        this.start = startTime
        this.end = endTime
        this.text = text

        this.startFormatted = TimeUtil.secondsToDisplayFormat(startTime)
    }

    clone() {
        return new TranscriptLine(this.start, this.end, this.text)
    }
}

export class Transcript {
    content

    constructor(content) {
        this.content = content
    }

    static fromVTT(vttString) {
        return new Promise((resolve, reject) => {
            const content = []

            // eslint-disable-next-line no-undef
            var parser = new WebVTT.Parser(window, WebVTT.StringDecoder())
            parser.oncue = (cue) => {
                content.push(new TranscriptLine(cue.startTime, cue.endTime, cue.text))
            }
            parser.onparsingerror = (e) => {
                // TODO
            }
            parser.onflush = () => {
                // Completely parsed
                resolve(new Transcript(content))
            }
            parser.parse(vttString)
            parser.flush()
        })
    }

    // Content is array of TranscriptLine
    static toVTTString(content) {
        const vtt = new WebVtt()
        content.forEach((tsLine) => {
            vtt.add(
                new VttCue({
                    startTime: tsLine.start,
                    endTime: tsLine.end,
                    payload: tsLine.text,
                }),
            )
        })
        return vtt.toString()
    }
}
