import { createTheme } from "@mui/material/styles"
import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider, createBrowserRouter, Outlet } from "react-router"
import "./titillium.css"
import ErrorElement from "./components/ErrorElement"
import EditorAppErrorElement from "./components/EditorApp/EditorAppErrorElement"
import DingScreen from "./components/DingScreen/DingScreen"
import EditorApp from "./components/EditorApp/EditorApp"
import AboutPage from "./components/Homepage/AboutPage.js"
import HelpPage from "./components/Homepage/HelpPage.js"
import Homepage from "./components/Homepage/Homepage"
import PricingPage from "./components/Homepage/PricingPage.js"
import PrivacyPage from "./components/Homepage/PrivacyPage.js"
import TermsPage from "./components/Homepage/TermsPage.js"
import Interview from "./components/Interview/Interview.js"
import TagIndex from "./components/TagIndex/TagIndex"
import reportWebVitals from "./reportWebVitals"

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'ui-sans-serif', 'system-ui',
            "Asap",
            // 'Titillium Web',
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
})

const router = createBrowserRouter(
    [
        {
            element: <><Outlet /></>,
            errorElement: <ErrorElement theme={theme}/>,
            children: [
                {
                    path: "/",
                    element: <Homepage theme={theme} />,
                },
                {
                    path: "/about",
                    element: <AboutPage theme={theme} />,
                },
                {
                    path: "/help",
                    element: <HelpPage theme={theme} />,
                },
                {
                    path: "/pricing",
                    element: <PricingPage theme={theme} />,
                },
                {
                    path: "/terms",
                    element: <TermsPage theme={theme} />,
                },
                {
                    path: "/privacy",
                    element: <PrivacyPage theme={theme} />,
                },
                {
                    path: "/app",
                    element: <EditorApp theme={theme} />,
                    errorElement: <EditorAppErrorElement theme={theme}/>,
                    children: [
                        {
                            path: "",
                            element: <DingScreen />,
                        },
                        {
                            path: "tagindex",
                            element: <TagIndex />,
                        },
                        {
                            path: "explorer/*",
                            children: [
                                {
                                    path: "*",
                                    element: <Interview />,
                                },
                            ],
                        },
                    ],
                },
            ]
        }
    ],
    {},
)

ReactDOM.createRoot(document.getElementById("root")).render(<RouterProvider router={router} />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
