import { Duration } from "luxon"

export class TimeUtil {
    static secondsToDisplayFormat(seconds) {
        const duration = Duration.fromObject({ seconds: seconds })
        if (duration.toMillis() < 3600000)
            // 1hr
            return duration.toFormat("mm:ss")
        else return duration.toFormat("h:mm:ss")
    }

    static roundUpSecondsToMinutes(seconds) {
        const roundedUp = Math.ceil(Math.round(seconds) / 60)
        return Math.max(1, roundedUp)
    }
}
